import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Organization from "../Pages/Users/Organization";

const Lms = lazy(() => import("../Pages/LMS/Lms"));
const Home = lazy(() => import("../Pages/Home/Home"));
const User = lazy(() => import("../Pages/Users/User"));
const Tasks = lazy(() => import("../Pages/LMS/tasks/Tasks"));
const Modules = lazy(() => import("../Pages/LMS/modules/Modules"));
const PageNotFound = lazy(() => import("../Pages/404/PageNotFound"));
const StudentDetail = lazy(() => import("../Pages/ProgressTracking/StudentDetail"));
const ProgressTracking = lazy(() => import("../Pages/ProgressTracking/ProgressTracking"));
const FeedbackParameters = lazy(() => import("../Pages/FeedbackParameters/FeedbackParameters"));

const RoutesComponent = () =>
  useRoutes([
    {
      path: "/",
      element: <Navigate replace to="/home" />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/lms",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Lms />,
        },
        {
          path: "modules/:id",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Modules />,
            },
            {
              path: "task/:id",
              element: <Tasks />,
            },
          ],
        },
      ],
    },
    {
      path: "/progress-tracking",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <ProgressTracking />,
        },
        {
          path: "/progress-tracking/:id",
          element: <StudentDetail />,
        },
      ],
    },
    {
      path: "/organization",
      element: <Outlet />,
      children: [
        {
          path: '',
          index: true,
          element: <Organization />,
        },
        {
          path: "user/:id",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <User />,
            },
          ]
        },
      ],
    },
    {
      path: "/feedback-parameters",
      element: <FeedbackParameters />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

export default RoutesComponent;
