import { TextField } from "@mui/material";
import { Validation } from "../../Interface/Interface";

const Input = (props: Validation) => {
  const {
    label,
    type,
    id,
    name,
    onChange,
    select,
    value,
    fullWidth,
    FormHelperTextProps,
    variant,
    InputLabelProps,
    multiline,
    color,
    error,
    minRows,
    helperText,
    defaultValue,
  } = props;

  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      color={color}
      type={type}
      name={name}
      select={select}
      fullWidth={fullWidth}
      minRows={minRows}
      onChange={onChange}
      multiline={multiline}
      value={value}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      FormHelperTextProps={FormHelperTextProps}
      autoComplete="new-password"
      sx={{
        ".MuiFormHelperText-root": {
          marginLeft: "0px",
        },
        width: "100%",
        marginBottom: "1rem",
      }}
    />
  );
};
export default Input;
