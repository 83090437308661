import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../api/api";
import authSlice from "../slices/authSlice";
import MainSidebarSlice from "../slices/MainSidebarSlice";
import progressTrackingSlice from "../slices/progressTrackingSlice";
import snackbarSlice from "../slices/snackbarSlice";

const rootReducer = combineReducers({
  authSlice: authSlice,
  mainSidebarSlice: MainSidebarSlice,
  progressTrackingSlice: progressTrackingSlice,
  snackbarSlice,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
