import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

const initialState = {
  userProgress: {
    ahead_users: 0,
    on_track_users: 0,
    on_risk_users: 0,
    not_started: 0,
  },
};

const progressTrackingSlice = createSlice({
  name: "Progress-Tracking",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getProgressList.matchFulfilled,
      (state, { payload }) => {
        if (payload && payload.data && payload.data.userProgress) {
          state.userProgress = payload?.data?.userProgress;
        }
      }
    );
  },
});

export default progressTrackingSlice.reducer;
