import { createApi } from "@reduxjs/toolkit/query/react";
import { apiRoot, baseQuery } from "../../global";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "Programs",
    "Modules",
    "Tasks",
    "Organization",
    "Learner",
    "Mentors",
    "Feedback",
    "Progress",
    "Plan",
  ],
  endpoints: (builder) => ({
    tappLogin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
    }),

    getAllPrograms: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
      }),
      providesTags: ["Programs"],
    }),

    addProgramsLMS: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    deleteProgramme: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    getAllModules: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?program_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Modules"],
    }),

    addModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    deleteModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    getAllTasks: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?module_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),

    addTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    editTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    deleteTask: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?task_id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),

    getAllfeedbackParams: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
      }),
      providesTags: ["Feedback"],
    }),

    addFeedback: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    deleteFeedbackParameter: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Feedback"],
    }),

    updateFeedback: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    addTaskImage: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    deleteTaskImage: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    getLearnerList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Learner"],
    }),

    addLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Learner"],
    }),

    editLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Learner"],
    }),

    deleteLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.orgId}&learner_id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Learner"],
    }),

    allMentorByStackList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.org_id}&tech_stack=${data.tech_id}`,
        method: "GET",
      }),
      providesTags: ["Learner"],
    }),

    getMentorByStackList: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.org_id}&tech_stack=${data.tech_id}`,
        method: "GET",
      }),
      invalidatesTags: ["Learner"],
    }),

    getMentorList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Mentors"],
    }),

    addMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Mentors"],
    }),

    editMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Mentors"],
    }),

    deleteMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?mentor_id=${data.id}&org_id=${data.orgId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Mentors"],
    }),

    getProgressList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Progress"],
    }),

    getProgressLearner: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Progress"],
    }),

    getPlan: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Plan"],
    }),

    getAllOrganizationList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Organization"],
    }),

    addEditOrganization: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Organization"],
    }),

    addFeddbackModule: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: {
          user_id: data.body,
        },
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const {
  useTappLoginMutation,
  useGetAllProgramsQuery,
  useAddProgramsLMSMutation,
  useDeleteProgrammeMutation,
  useGetAllModulesQuery,
  useAddModulesMutation,
  useDeleteModulesMutation,
  useGetAllTasksQuery,
  useAddTasksMutation,
  useEditTasksMutation,
  useDeleteTaskMutation,
  useGetAllfeedbackParamsQuery,
  useAddFeedbackMutation,
  useDeleteFeedbackParameterMutation,
  useUpdateFeedbackMutation,
  useAddTaskImageMutation,
  useGetAllOrganizationListQuery,
  useGetLearnerListQuery,
  useDeleteLearnerMutation,
  useAddLearnerMutation,
  useEditLearnerMutation,
  useGetMentorByStackListMutation,
  useLazyAllMentorByStackListQuery,
  useGetMentorListQuery,
  useAddMentorMutation,
  useEditMentorMutation,
  useDeleteMentorMutation,
  useDeleteTaskImageMutation,
  useGetProgressListQuery,
  useGetProgressLearnerQuery,
  useGetPlanQuery,
  useAddEditOrganizationMutation,
  useAddFeddbackModuleMutation,
} = api;
