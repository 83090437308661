import { Button } from "@mui/material";
import { ButtonValues } from "../../Interface/Interface";

const Buttons = (props: ButtonValues) => {
  const { name, type, variant, color, size, onClick, disabled } = props;

  return (
    <>
      <Button
        variant={variant}
        color={color}
        size={size}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {name}
      </Button>
    </>
  );
};

export default Buttons;
