import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const Login = lazy(() => import("../Pages/Login/Login"));

const PublicRoutes = () => {
  let publicRoutes = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  return publicRoutes;
};

export default PublicRoutes;
