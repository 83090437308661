import styled from "styled-components";

// [COMPONENTS] REUSABLES > LOADER
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
`;

// [PAGES] FEEDBACK-PARAMETERS > ADD-FEEDBACK
// &&
// [PAGES] LMS > MODULES > ADD-MODULES
const customStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// [PAGES] LMS > TASKS > ADD-TASK-MODAL
const styleAddTaskModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "550px",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// [PAGES] LOGIN
const ContainerFluid = styled.div`
  background-color: var(--primaryColor);
  min-height: 100vh;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const FormBox = styled.div`
  width: 30rem;
  background-color: var(--secondaryColor);
  padding: 2rem;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
`;

const Heading = styled.div`
  text-align: center;
`;

const Footer = styled.div`
  padding: 1rem 0rem;
  text-align: center;
`;

const PasswordInputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  right: 0.5rem;
  top: 15px;
  cursor: pointer;
`;

export {
  LoaderContainer,
  customStyle,
  ContainerFluid,
  FormBox,
  InputDiv,
  Heading,
  Footer,
  PasswordInputDiv,
  Span,
  styleAddTaskModal,
};
