import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Switch,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Buttons from "../../components/reusables/Buttons";
import Loader from "../../components/reusables/Loader";
import { useGetAllOrganizationListQuery } from "../../redux/api/api";
import OrganizationModal from "./mentor/OrganizationModal";
import { organizationTypeDetailsType } from "../../Interface/Interface";
import ErrorMessage from "../../components/reusables/ErrorMessage";

export const initialValue = {
  id: "",
  name: "",
  email: "",
  contact_number: "",
  address: "",
  plan: {
    id: "",
    type: "",
  },
};

const Organization = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [modal, setModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [organizationTypeDetails, setOrganizationTypeDetails] =
    useState<organizationTypeDetailsType>(initialValue);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const orgArry = [
    "Name",
    "Email",
    "Plan",
    "Contact",
    "Address",
    "Actions",
  ];

  const reqObject = {
    url: "admin/orgs",
    id: params.id,
  };

  const { data, isLoading, isError, isSuccess }: any =
    useGetAllOrganizationListQuery(reqObject);

  const handleAddOganization = () => {
    setModal(true);
    setOrganizationTypeDetails(initialValue);
  };

  const handleEdit = (id: any) => {
    const filterData: any = data?.data?.filter(
      (item: any) => item.id === id
    )[0];
    if (!filterData) return;
    setOrganizationTypeDetails(filterData);
    setModal(true);
    setIsEditing(true);
  };

  const handleUsers = (id: any) => navigate(`user/${id}`);

  return (
    <>
      <Box mb="20px" display="flex" justifyContent="space-between">
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Typography
            fontSize="18px"
            fontFamily="mulish-bold"
            fontWeight="600"
            variant="body1"
          >
            Organization
          </Typography>
        </Box>
        <Buttons
          variant="contained"
          color="primary"
          name="Add Organization"
          onClick={handleAddOganization}
          type="submit"
        />
      </Box>
      {isLoading ? (
        <Loader height="200px" />
      ) : isError ? (
        <ErrorMessage/>
      ) : isSuccess && data?.data?.length === 0 ? (
        <Typography variant="h6">No data found</Typography>
      ) : isSuccess && data?.data?.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{boxShadow:'none',borderRadius:'0px',}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {orgArry.map((item: string, index: any) => {
                    return <TableCell key={index}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data ? data?.data.map((row: any) => {
                      return (
                        <TableRow
                          onClick={() => handleUsers(row.id)}
                          key={row.id}
                          hover={true}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell component="th" scope="row" size="small">
                            {row.name}
                          </TableCell>
                          <TableCell size="small">{row.email}</TableCell>
                          <TableCell size="small">{row?.plan?.type}</TableCell>
                          <TableCell size="small">
                            {row.contact_number ? row.contact_number : "N/A"}
                          </TableCell>
                          <TableCell size="small">{row.address}</TableCell>
                          <TableCell onClick={(e: any) => e.stopPropagation()} size="small">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "5px",
                                alignItems:'center'
                              }}
                            >
                              <IconButton onClick={() => handleEdit(row.id)}>
                                <EditIcon
                                  color="success"
                                  sx={{ cursor: "pointer" }}
                                />
                              </IconButton>
                              <Switch {...label} defaultChecked size="small"/>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}

      {modal ? (
        <OrganizationModal
          modal={modal}
          setModal={setModal}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setOrganizationTypeDetails={setOrganizationTypeDetails}
          organizationTypeDetails={organizationTypeDetails}
        />
      ) : null}
    </>
  );
};

export default Organization;
