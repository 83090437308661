import * as yup from "yup";

// [PAGES] FEEDBACK-PARAMETERS > ADD-FEEDBACK
const feedbackSchema = yup.object().shape({
  name: yup.string().required("Please enter the Feedback parameter name"),
});

// [PAGES] LMS > ADD-PROGRAME-MODAL
const lmsProgramSchema = yup.object().shape({
  name: yup.string().required("Please enter the programe name"),
});

// [PAGES] LMS > MODULES > ADD-MODULES
const modulSchema = yup.object().shape({
  name: yup.string().required("Please enter the module name"),
  description: yup.string().required("Please enter the module desciption"),
});

// [PAGES] LMS > TASKS > ADD-TASK-MODAL
const taskSchema = yup.object().shape({
  title: yup.string().required("Please enter title"),
  desc_title: yup.string().required("Please enter description title"),
  number_of_days: yup.number().positive("Must be more than 0").required("Please enter number of days"),
});

// [PAGES] LOGIN > LOGIN
const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

// [PAGES] USERE > MENTOR > ORGANIZATION-MODAL
const organizationSchema = yup.object().shape({
  name: yup.string().required("Please enter Your Organization Name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  contact_number: yup
    .string()
    .max(10)
    .phone("IN", true, "Please enter your phone number")
    .required(),
  address: yup.string().required("Please enter Your Address"),
});

export {
  feedbackSchema,
  lmsProgramSchema,
  modulSchema,
  taskSchema,
  validationSchema,
  organizationSchema,
};
