import { createSlice, Slice } from "@reduxjs/toolkit";
import { AuthState } from "../../Interface/Interface";

const initialState: AuthState = {
  userToken: null,
};

const authSlice: Slice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    logoutAction: (): AuthState => initialState,
    storeLoginToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

export const { storeLoginToken, logoutAction } = authSlice.actions;
export default authSlice.reducer;
