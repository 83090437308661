import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  styled as muiStyled,
  useTheme,
  Theme,
  CSSObject,
  Box,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import {
  ChevronLeft,
  TrendingUp,
  Feedback,
  Menu,
  Inbox,
  Mail,
  Person,
  Logout,
} from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import BarChartIcon from '@mui/icons-material/BarChart';
import { storeLoginToken } from "../redux/slices/authSlice";
import { setSelectedOption } from "../redux/slices/MainSidebarSlice";
import tapp_main_logo from "../assets/images/tapp_main_logo.svg";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: "#18181a",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#18181a",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "250px",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "#1976d2",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    backgroundColor: "#1976d2",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainSidebarHeader = (props: any) => {
  const { children } = props;

  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const refer: any = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const home = location.pathname.split("/")[1].toLowerCase() === "home";

  const handleOptionsClick = (text: string) => {
    if (home) dispatch(setSelectedOption("Dashboard"));
    dispatch(setSelectedOption(text));
    navigate(`/${text === "dashboard" ? "home" : text}`);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{ boxShadow: "none", borderBottom: "1px solid #ccc" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <Menu sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <Typography
              display="flex"
              justifyContent="space-between"
              align="left"
              width="100%"
              component="div"
            >
              <Typography
                variant="h6"
                onClick={() => {
                  navigate("/home");
                  dispatch(setSelectedOption("Dashboard"));
                }}
                noWrap
                component="div"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  fontSize: "1.7rem",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  display: "flex",
                }}
              >
                <img style={{ width: "2rem" }} src={tapp_main_logo} />
              </Typography>
              <IconButton
                sx={{
                  color: theme.palette.primary.main,
                }}
                aria-label="add an alarm"
                onClick={() => {
                  dispatch(storeLoginToken(""));
                  dispatch(setSelectedOption(""));
                  navigate("/");
                }}
              >
                <Logout />
              </IconButton>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeft sx={{ color: theme.palette.primary.main }} />
              ) : (
                <ChevronLeft sx={{ color: theme.palette.primary.main }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              "home",
              "lms",
              "progress-tracking",
              "organization",
              "feedback-parameters",
            ].map((text: string) => {
              return (
                <ListItem
                  id={text}
                  className={`${
                    location.pathname.split("/")[1].toLowerCase() === text
                      ? "linkactive"
                      : ""
                  }`}
                  key={text}
                  onClick={() => handleOptionsClick(text)}
                  disablePadding
                  ref={refer}
                  sx={{
                    display: "block",
                    color: theme.palette.primary.light,
                    textTransform: "uppercase",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="cdd"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: theme.palette.primary.light,
                      }}
                    >
                      {text === "lms" ? (
                        <Inbox />
                      ) : text === "organization" ? (
                        <Person />
                      ) : text === "progress-tracking" ? (
                        <TrendingUp />
                      ) : text === "feedback-parameters" ? (
                        <Feedback />
                      ) : (
                        <BarChartIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        text === "home"
                          ? "dashboard"
                          : text.split("-").join(" ")
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        textTransform: "capitalize",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MainSidebarHeader;
