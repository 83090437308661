import { useEffect, useState } from "react";
import { useFormik } from "formik";
import "yup-phone";
import {
  Box,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Select,
  FormHelperText,
} from "@mui/material";
import {
  useAddEditOrganizationMutation,
  useGetPlanQuery,
} from "../../../redux/api/api";
import CustomInput from "../../../components/reusables/CustomInput";
import Buttons from "../../../components/reusables/Buttons";
import { initialValue } from "../Organization";
import { organizationSchema } from "../../../Schema/Schema";
import { OrganizationModalType } from "../../../Interface/Interface";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";
import { customStyle } from "../../../Style/style";

const OrganizationModal = ({
  modal,
  setModal,
  isEditing,
  setIsEditing,
  organizationTypeDetails,
  setOrganizationTypeDetails,
}: OrganizationModalType) => {
  const [addEditOrganization] = useAddEditOrganizationMutation();
  const [planId, setPlanId] = useState<any>("");
  const [plan, setPlan] = useState("");
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      setPlan(organizationTypeDetails?.plan?.type);
      setPlanId(organizationTypeDetails?.plan?.id);
    } else {
      setPlan("");
      setPlanId("");
    }
  }, []);

  const planReqObj = {
    url: "admin/plans",
  };

  const { data } = useGetPlanQuery(planReqObj);

  const handleClose = () => {
    setModal(false);
    setOrganizationTypeDetails(initialValue);
  };

  const handleValidation = () =>
    setValidated(organizationTypeDetails?.plan?.type ? false : true);

  const handlePlanChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setPlan(event.target.value as string);
      setValidated(false);
    }
  };

  const handleSubmitOrganizationForm = async (values: any) => {
    if (!plan) return;
    setValidated(false);
    try {
      const reqObject: any = {
        url: "admin/add-edit-org",
        id: organizationTypeDetails?.id ? organizationTypeDetails.id : 0,
        name: values.name,
        email: values.email,
        contact_number: values.contact_number,
        address: values.address,
        plan_id: planId,
      };
      const resp: any = await addEditOrganization(reqObject).unwrap();
      if (resp.code === 3011 || resp.code === 3012) {
        dispatch(openAlert({
          message: resp.message,
          severity: "success",
        }));
      } else if (resp.code === 3013) {
        dispatch(openAlert({
          message: resp.message,
          severity: "warning",
        }));
      }
      setModal(false);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: organizationTypeDetails,
    validationSchema: organizationSchema,
    onSubmit: handleSubmitOrganizationForm,
  });

  const { errors, touched, handleChange, handleSubmit, values, isSubmitting } =
    formik;

  return (
    <>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="organization-modal-title"
        aria-describedby="organization-modal-description"
      >
        <Box sx={customStyle}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {organizationTypeDetails?.id ? "Edit" : "Add"} Organization
          </Typography>

          <form onSubmit={handleSubmit}>

            <CustomInput
              label="Organization Name"
              type="text"
              id="organizationID"
              name="name"
              onChange={handleChange}
              value={values.name}
              variant="outlined"
              color="primary"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />

            <CustomInput
              label="Email"
              type="email"
              id="emailID"
              name="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              color="primary"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />

            <CustomInput
              label="Contact Number"
              type="number"
              id="contactID"
              name="contact_number"
              onChange={handleChange}
              value={values.contact_number}
              variant="outlined"
              color="primary"
              error={touched.contact_number && Boolean(errors.contact_number)}
              helperText={touched.contact_number && errors.contact_number}
            />

            <CustomInput
              label="Address"
              type="text"
              id="addressID"
              name="address"
              onChange={handleChange}
              value={values.address}
              variant="outlined"
              color="primary"
              error={touched.address && Boolean(errors.address)}
              helperText={touched.address && errors.address}
            />

            <FormControl
              fullWidth
              sx={{ marginBottom: "15px" }}
              error={validated && !plan ? true : false}
            >
              <InputLabel id="plan">Plan</InputLabel>
              <Select
                labelId="planLabel"
                id="planId"
                value={plan ? plan : ""}
                label="Plan"
                onChange={handlePlanChange}
              >
                {data?.data.map((items: any) => {
                  return (
                    <MenuItem
                      onClick={() => setPlanId(items.id)}
                      value={items.type}
                      key={items.id}
                    >
                      {items.type}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText sx={{ marginLeft: "0px" }}>
                {validated && !plan ? "Required" : ""}
              </FormHelperText>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
              <Buttons
                type="submit"
                name="Cancel"
                variant="contained"
                color="info"
                size="small"
                onClick={handleClose}
              />
              <Buttons
                onClick={handleValidation}
                type="submit"
                disabled={isSubmitting || !plan}
                name={isSubmitting ? "Saving" : "Save"}
                variant="contained"
                color="primary"
                size="small"
              />
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default OrganizationModal;
