import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState:any = {
  selectedOption: "",
};

const mainSidebarSlice:Slice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
  },
});

export const { setSelectedOption } = mainSidebarSlice.actions;
export default mainSidebarSlice.reducer;
